<template>
  <v-app>
    <v-app-bar
      app 
      color="primary"
      dark
    >
      <div class="d-flex align-left">
        <v-img
          alt="Vrombr Logo"
          class="shrink mr-2"
          contain
          src="../src/assets/logo_vrombr_white.png"
          transition="scale-transition"
          height="50"
        />
      </div>

      <v-spacer></v-spacer>

      <v-toolbar-items>
  <v-icon @click="logout">mdi-logout</v-icon>
</v-toolbar-items>  </v-app-bar>

    <v-main>
      <LiveRace/>
    </v-main>
  </v-app>
</template>

<script>
import LiveRace from './components/LiveRace';

export default {
  name: 'App',

  components: {
    LiveRace
  },

  data: () => ({
    //
  }),
  methods: {
    async logout() {
      // disconnect
      await this.$keycloak.logout();

      // redirect user to login page
      window.location.href = this.$keycloak.createLoginUrl();
    },
 
  }
};
</script>
