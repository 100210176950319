

<template>
  <v-container>
    <v-expansion-panels>
      
      <v-expansion-panel>
        <v-expansion-panel-header>
          Track Opening hours
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <!-- Opening days and time pickers -->
          <v-row>
            <!-- Checkbox and time pickers for each day -->
            <v-col v-for="(day, index) in daysOfWeek" :key="index" cols="1.5">
              <v-checkbox   
                :disabled="raceState !== RaceStopped"
                :input-value="openingDays[day.value]"
                :label="day.label"
                @change="updateOpeningDay(day.value, $event)"
              ></v-checkbox>

              <!-- Start time picker -->
              <v-menu v-if="openingDays[day.value]" v-model="timePicker[day.value].start" :close-on-content-click="false" :nudge-right="40" transition="scale-transition">
                <template v-slot:activator="{ on }">
                  <v-text-field
                    :disabled="raceState !== RaceStopped"
                    v-model="openingHours[day.value].start"
                    label="Opening Time"
                    readonly
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-time-picker
                  format="24hr"
                  scrollable
                  v-model="openingHours[day.value].start"
                  full-width
                  @input="updateTime(day.value, 'start')"
                ></v-time-picker>
              </v-menu>

              <!-- End time picker -->
              <v-menu v-if="openingDays[day.value]" v-model="timePicker[day.value].end" :close-on-content-click="false" :nudge-right="40" transition="scale-transition">
                <template v-slot:activator="{ on }">
                  <v-text-field
                    :disabled="raceState !== RaceStopped"
                    v-model="openingHours[day.value].end"
                    label="Closing Time"
                    readonly
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-time-picker
                  v-model="openingHours[day.value].end"
                  full-width
                  format="24hr"
                  scrollable
                  @input="updateTime(day.value, 'end')"
                ></v-time-picker>
              </v-menu>
            </v-col>
          </v-row>
          <!-- Save button -->
          <!-- <v-btn @click="saveOpeningHours">Save Opening Hours</v-btn> -->
        </v-expansion-panel-content>
    </v-expansion-panel>
    
  <v-expansion-panel>
        <v-expansion-panel-header>
         Track Settings
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-row>
      <v-col cols="6">
        <v-switch
          :disabled="raceState !== RaceStopped"
          v-model="isPublic"
          color="blue"
          :label="isPublic ? 'Public' : 'Private'"
          :label-value="isPublic"
          @change="handleChange"
        ></v-switch>
      </v-col>

    </v-row>
        <v-row class="text-center">
      <v-col cols="12">
        <v-radio-group v-model="raceMode" row>
          Race mode: &nbsp;
          <v-radio
            label="RaceAsYouGo"
            value="RaceAsYouGo"
            :disabled="raceState !== RaceStopped"
          ></v-radio>
          <v-radio
            label="Versus"
            value="Versus"
            :disabled="raceState !== RaceStopped"
          ></v-radio>
          <v-radio
            label="Arena"
            value="Arena"
            :disabled="raceState !== RaceStopped"
          ></v-radio>
        </v-radio-group>
      </v-col>
    </v-row>


      <v-row>
      <v-col cols="6" v-if="raceMode !== 'Arena'">
        <v-slider
          :disabled="raceState !== RaceStopped"
          v-model="numLaps"
          :label="'Laps per race: ' + numLaps"
          min="1"
          max="10"
          thumb-label
          @change="updateNumLaps()"
        ></v-slider>
      </v-col>
      <v-col cols="6" v-if="raceMode == 'Arena'">
        <v-slider
          :disabled="raceState !== RaceStopped"
          v-model="battleTime"
          :label="'Battle time (s): ' + battleTime"
          min="60"
          max="300"
          thumb-label
        ></v-slider>
      </v-col>
      <v-col cols="6" v-if="raceMode == 'Arena'">
        <v-slider
          :disabled="raceState !== RaceStopped"
          v-model="battleWaitingTime"
          :label="'Lobby Waiting time (s): ' + battleWaitingTime"
          min="5"
          max="300"
          thumb-label
        ></v-slider>
      </v-col>
    </v-row>
    <v-row>
    <v-col cols="3">
    <v-text-field
      v-model.number="timeoutSetting"
      :label="'Lap timeout (seconds)'"
      type="number"
      min="0"
      :disabled="raceState !== RaceStopped"
    ></v-text-field>
  </v-col>
    </v-row>

    <v-row>
    <v-col cols="3">
    <v-text-field
      v-model.number="maxRetry"
      :label="'Max retry'"
      type="number"
      min="0"
      :disabled="raceState !== RaceStopped"
    ></v-text-field>
  </v-col>
    </v-row>

      </v-expansion-panel-content>

      </v-expansion-panel>
    </v-expansion-panels>


    <v-row class="text-center">
      <v-col cols="12">

        <v-container
          v-if="raceState == RaceAboutToStart"
          style="margin-top: 1em"
        >
          <h3>🔴🔴🔴⚫⚫</h3>
          <h2>{{raceMode}} is about to start</h2>
          <p>The countdown should appear soon on the finish line</p>
        </v-container>

        <v-container
          v-if="raceState == RacePending"
          style="margin-top: 1em"
        >
          <h3>🔴🔴🔴🔴🔴</h3>
          <h2>{{raceMode}} is waiting criteria to be met</h2>
          <p>Waiting...</p>
        </v-container>

        <v-container v-if="raceState == RaceOngoing" style="margin-top: 1em">
          <h3>🟢🟢🟢🟢🟢</h3>
          <h2>{{raceMode}} is ongoing</h2>
          <p>You can see the laps times below</p>
        </v-container>
        <v-container v-if="displayFinishMessage" style="margin-top: 1em">
          <h3>🏁🏁🏁🏁🏁</h3>
          <h2>{{raceMode}} is finished for all players</h2>
        </v-container>

        <v-btn
          v-if="
            raceState == RaceStopped &&
            !displayFinishMessage &&
            raceMode == 'RaceAsYouGo'
          "
          color="primary"
          :loading="startPending"
          :disabled="startPending"
          @click="startRace()"
          >Start race!</v-btn
        >
        <v-btn
          v-if="
            raceState == RaceStopped &&
            !displayFinishMessage &&
            raceMode == 'Versus'
          "
          color="primary"
          :loading="startPending"
          :disabled="startPending"
          @click="startRace()"
          >All cars and players are ready ➡ Start race!</v-btn
        >
        <v-btn
          v-if="
            raceState == RaceStopped &&
            !displayFinishMessage &&
            raceMode == 'Arena'
          "
          color="primary"
          :loading="startPending"
          :disabled="startPending"
          @click="startRace()"
          >Start Battle!</v-btn
        >

        <v-btn
          v-if="raceState == RaceOngoing"
          color="primary"
          :loading="stopPending"
          :disabled="stopPending"
          @click="stopRace()"
          >stop race</v-btn
        >

        <v-btn
          v-if="raceState == RacePending"
          color="primary"
          :loading="stopPending"
          :disabled="stopPending"
          @click="stopRace()"
          >cancel race</v-btn
        >
      </v-col>
    </v-row>


    <v-row class="text-center">
      <v-col cols="12">
        <v-data-table :headers="headers" :items="cars"></v-data-table>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <h3>Connected Cars</h3>
        <direction-offset
          v-for="i in connectedCars.length"
          :key="i - 1"
          :carId="connectedCars[i - 1]"
          :directionOffset="connectedCarsDirectionOffset[i - 1]"
        ></direction-offset>
        <h3>Disconnected Cars</h3>
        <li
          v-for="car of disconnectedCars"
          :key="car"
          style="padding-top: 16px; padding-bottom: 16px"
        >
          id: {{ car }}
        </li>
      </v-col>
    </v-row>
    <v-row class="text-center">
      <v-col cols="12">
        <v-btn
          style="display: block"
          v-for="i of connectedCars"
          :key="i"
          @click="simulateLap(i)"
          >Simulate a lap for car {{ i }}
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>


<style>
.v-label.theme--light {
  color: rgba(0, 0, 0) !important;
}
.v-card {
  margin-top: 1em;
}
</style>


<script>
import gql from "graphql-tag";
import DirectionOffset from "./DirectionOffset.vue";

export default {
  components: { DirectionOffset },
  name: "LiveRace",
  data: () => ({
    statusRefreshTimer: null,
    raceMode: "RaceAsYouGo",
    raceModeBackend: "",
    RaceStopped: 0,
    RaceOngoing: 1,
    RacePending: 2,
    RaceAboutToStart: 3,
    timeoutSetting: 0,
    timeoutSettingBackend: 0,
    daysOfWeek: [
      { label: 'Monday', value: 'mon' },
      { label: 'Tuesday', value: 'tue' },
      { label: 'Wednesday', value: 'wed' },
      { label: 'Thursday', value: 'thu' },
      { label: 'Friday', value: 'fri' },
      { label: 'Saturday', value: 'sat' },
      { label: 'Sunday', value: 'sun' },
    ],
    openingDays: {
      mon: true,
      tue: true,
      wed: true,
      thu: true,
      fri: true,
      sat: false,
      sun: false,
    },
    openingHours: {
        mon: { start: '11:00', end: '18:00' },
        tue: { start: '11:00', end: '18:00' },
        wed: { start: '11:00', end: '18:00' },
        thu: { start: '11:00', end: '18:00' },
        fri: { start: '11:00', end: '18:00' },
        sat: { start: '11:00', end: '18:00' },
        sun: { start: '11:00', end: '18:00' },
      },    
    openingDaysBackend: {
      mon: false,
      tue: false,
      wed: false,
      thu: false,
      fri: false,
      sat: false,
      sun: false,
    },
    openingHoursBackend: {
        mon: { start: '', end: '' },
        tue: { start: '', end: '' },
        wed: { start: '', end: '' },
        thu: { start: '', end: '' },
        fri: { start: '', end: '' },
        sat: { start: '', end: '' },
        sun: { start: '', end: '' },
      },
    timePicker: {
      mon: { start: false, end: false },
      tue: { start: false, end: false },
      wed: { start: false, end: false },
      thu: { start: false, end: false },
      fri: { start: false, end: false },
      sat: { start: false, end: false },
      sun: { start: false, end: false },
    },
    isPublic: false,
    isPublicBackend: false,
    
    maxRetry: 2,
    maxRetryBackend: 0,
    raceState: 0,
    raceStartTimestamp: 0,
    numLaps: 3,
    numLapsBackend: 0,
    battleTime: 90,
    battleTimeBackend: 0,
    battleWaitingTime: 8,
    battleWaitingTimeBackend: 0,

    startPending: false,
    stopPending: false,
    displayFinishMessage: false,

    connectedCars: [],
    disconnectedCars: [],
    connectedCarsDirectionOffset: [],
    

    headers: [],
    cars: [
      {
        id: "1",
        player: "alice",
        lapTimes: [],
      },
      {
        id: "2",
        player: "bob",
        lapTimes: [],
      },
    ],
  }),
  mounted() {
    this.updateNumLaps();
    this.updateRaceStatus();
  },
  methods: {

    handleChange(value) {
      this.isPublic = value;
    },
    updateOpeningDay(day, value) {
      this.$set(this.openingDays, day, value);
    },
    updateTime(day, timeType) {
      const selectedTime = this.openingHours[day][timeType];
      console.log(`Selected ${timeType} time for ${day}: ${selectedTime}`);
    },

    
    saveOpeningHours() {
  // Create an object to store the opening hours data
      const openingTime = [];

      // Iterate over each day in openingDays
      for (const day in this.openingDays) {
        if (Object.prototype.hasOwnProperty.call(this.openingDays, day)) {
          const weekDay = day.toUpperCase(); // The key is the weekDay
          const isOpen = this.openingDays[day]; // The value is whether it's open or not

        if (isOpen) {
          // Construct the opening time object for the day
          openingTime.push({
            weekDay: weekDay,
            openingDetails: {
              isOpen: true, // Set opening to true
              startTime: this.openingHours[day].start, // Set the start time
              endTime: this.openingHours[day].end, // Set the end time
            }
          });
        } else {
          // If the day is not marked as open, set opening to false
          openingTime.push({
            weekDay: weekDay,
            openingDetails: {
              isOpen: false,
              startTime: '', // You can set null for start and end time if needed
              endTime: '',
            }
          });
          }
        }
      }

      return openingTime
    },

    async startRace() {

      this.saveOpeningHours()
      this.startPending = true;
      setTimeout(() => (this.startPending = false), 15000);

      const resp = await this.$apolloClient.mutate({
        mutation: gql`
          mutation setTrackOpeningTime($openingTime: [TrackOpeningHoursInput!]!) {
            setTrackOpeningTime(openingTime: $openingTime)
          }
        `,
        variables: {
          openingTime: this.saveOpeningHours()
        },
      });

      console.log(`result start ${resp}`);
      const result = await this.$apolloClient.mutate({
        mutation: gql`
          mutation StartRace($raceStart: RaceStartInput) {
            startRace(raceStart: $raceStart)
          }
        `,
        variables: {
          raceStart: {
            raceMode: this.raceMode,
            numLaps: this.numLaps,
            battleTime: this.battleTime,
            waitingTime: this.battleWaitingTime,
            isPublic: this.isPublic,
            timeout: this.timeoutSetting,
            maxRetry: this.maxRetry
          },
        },
      });
      console.log(`result start ${result}`);
      this.statusRefreshTimer = setTimeout(this.updateRaceStatus, 1000);
    },
    async stopRace() {
      this.stopPending = true;
      setTimeout(() => (this.stopPending = false), 15000);
      const result = await this.$apolloClient.mutate({
        mutation: gql`
          mutation {
            stopRace
          }
        `,
      });
      console.log(`race stop result ${result}`);
    },
    msToString(timestampMs) {
      const minutes = Math.floor(timestampMs / 1000 / 60);
      const remainingTimeMs = timestampMs - minutes * 60 * 1000;
      const seconds = Math.floor(remainingTimeMs / 1000);
      const ms = remainingTimeMs - seconds * 1000;
      return `${minutes}:${seconds}.${ms}`;
    },
    updateNumLaps() {
      this.headers = [
        { text: "Car", value: "id" },
        { text: "Player", value: "player" },
        { text: "Ranking", value: "ranking" },
      ];
      for (let lapId = 1; lapId <= this.numLaps; lapId++) {
        this.headers.push({ text: `Lap ${lapId}`, value: `lap${lapId}` });
      }
      this.headers.push({ text: "Total", value: "total" });
    },

    areOpeningHoursDifferent(obj1, obj2) {
      const days = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'];

      for (const day of days) {
        if (obj1[day].start !== obj2[day].start || obj1[day].end !== obj2[day].end) {
          return true;
        }
      }

      return false;
    },

    areOpeningDaysDifferent(obj1, obj2) {
      const days = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'];

      for (const day of days) {
        if (obj1[day] !== obj2[day]) {
          return true;
        }
      }

      return false;
    },

    async updateRaceStatus() {
      // Call to the graphql mutation
      const result = await this.$apolloClient.query({
        // Query
        query: gql`
          query {
            getRaceStatus {
              raceMode
              raceState
              startTimestamp {
                timestamp
                ms
              }
              numLaps
              associatedCars {
                carId
                playerId
                lapsDurationMs
                ranking
              }
              battleTime
              waitingTime
              connectedCarsId
              connectedCarsDirectionOffset
              disconnectedCarsId
              isPublic
              timeout
              maxRetry
            }
          }
        `,
      });

      const hourResp = await this.$apolloClient.query({
        // Query
        query: gql`
          query {
            getTrackOpeningTime {
              weekDay
              openingDetails {
                isOpen
                startTime
                endTime
              }
            }
          }
        `,
      });
      console.log(`race status:\n ${JSON.stringify(result)}`);
      
      console.log(`track opening:\n ${JSON.stringify(hourResp)}`);

      const trackOpeningTimes = hourResp.data.getTrackOpeningTime;

      const openingDaysTemp = {}
      const openingHoursTemp = {}
      trackOpeningTimes.forEach(({ weekDay, openingDetails }) => {
        const day = weekDay.toLowerCase(); // Convert weekDay to lowercase to match local state keys
        try {
          openingDaysTemp[day] = openingDetails.isOpen;
          openingHoursTemp[day] = {
            start: openingDetails.startTime || '',
            end: openingDetails.endTime || ''
          }

        } catch (err){
          console.log("error found : ", err)
        }
      });
      if (this.areOpeningDaysDifferent(openingDaysTemp, this.openingDaysBackend)) {
        this.openingDaysBackend = {...openingDaysTemp};
        this.openingDays = {...this.openingDaysBackend};
      }
      if (this.areOpeningHoursDifferent(openingHoursTemp, this.openingHoursBackend)) {
        this.openingHoursBackend = JSON.parse(JSON.stringify(openingHoursTemp));
        this.openingHours = JSON.parse(JSON.stringify(this.openingHoursBackend));
      }
      if (result.data.getRaceStatus.raceMode !== this.raceModeBackend) {
        this.raceModeBackend = result.data.getRaceStatus.raceMode;
        this.raceMode = this.raceModeBackend;
        console.log(`update racemode from ${this.raceMode} to ${this.raceModeBackend}`);
      }
      if ( result.data.getRaceStatus.battleTime !== this.battleTimeBackend ) {
        this.battleTimeBackend = result.data.getRaceStatus.battleTime;
        this.battleTime = this.battleTimeBackend;
      }
      if (result.data.getRaceStatus.waitingTime !== this.battleWaitingTimeBackend) {
        this.battleWaitingTimeBackend = result.data.getRaceStatus.waitingTime;
        this.battleWaitingTime = this.battleWaitingTimeBackend;
      }
      if (result.data.getRaceStatus.numLaps !== this.numLapsBackend) {
        this.numLapsBackend = result.data.getRaceStatus.numLaps;
        this.numLaps = this.numLapsBackend;
      }

      this.connectedCars = result.data.getRaceStatus.connectedCarsId;
      this.connectedCarsDirectionOffset =
        result.data.getRaceStatus.connectedCarsDirectionOffset;
      this.disconnectedCars = result.data.getRaceStatus.disconnectedCarsId;

      if (result.data.getRaceStatus.isPublic !== this.isPublicBackend) {
        this.isPublicBackend = result.data.getRaceStatus.isPublic;
        this.isPublic = this.isPublicBackend;
      }
      if (result.data.getRaceStatus.maxRetry !== this.maxRetryBackend) {
        this.maxRetryBackend = result.data.getRaceStatus.maxRetry;
        this.maxRetry = this.maxRetryBackend;
      }
      if (result.data.getRaceStatus.timeout !== this.timeoutSettingBackend) {
        this.timeoutSettingBackend = result.data.getRaceStatus.timeout;
        this.timeoutSetting = this.timeoutSettingBackend;
      }

      const raceState = result.data.getRaceStatus.raceState;
      console.log(`race state:\n ${JSON.stringify(raceState)}`);

      if (raceState == "Ongoing") {
        this.raceState = this.RaceOngoing;
        this.statusRefreshTimer = setTimeout(this.updateRaceStatus, 2000);
      } else if (
        raceState == "Stopped" &&
        this.raceState !== this.RaceStopped
      ) {
        this.raceState = this.RaceStopped;
        this.displayFinishMessage = true;
        setTimeout(() => {
          this.displayFinishMessage = false;
          this.updateRaceStatus();
        }, 5000);
        return;
      } else if (raceState == "AboutToStart") {
        this.raceState = this.RaceAboutToStart;
        this.statusRefreshTimer = setTimeout(this.updateRaceStatus, 2000);
      } else if (raceState == "Pending") {
        this.raceState = this.RacePending;
        this.statusRefreshTimer = setTimeout(this.updateRaceStatus, 2000);
      } else {
        this.statusRefreshTimer = setTimeout(this.updateRaceStatus, 2000);
      }

      if (
        this.raceState == this.RaceOngoing ||
        this.raceState == this.RaceAboutToStart
      ) {
        this.raceMode = result.data.getRaceStatus.raceMode;
      }

      const cars = result.data.getRaceStatus.associatedCars;
      let updatedCars = [];
      for (const car of cars) {
        const resp = await this.$apolloClientUser.query({
          // Query
          query: gql`
              query GetUserName($playerId: String) {
                getUserName(playerId: $playerId)
              }
            `,
            variables: {
              playerId: car?.playerId,
            },
        });
        const playerName = resp.data.getUserName
        const updatedCar = {
          id: car.carId,
          player: playerName,
        };
        if (this.raceState == this.RaceOngoing || this.displayFinishMessage) {
          let sumTimeMs = 0;
          for (let lapId = 1; lapId <= car.lapsDurationMs.length; lapId++) {
            updatedCar[`lap${lapId}`] = this.msToString(
              car.lapsDurationMs[lapId - 1]
            );
            sumTimeMs += car.lapsDurationMs[lapId - 1];
          }
          updatedCar[`ranking`] = car.ranking;
          updatedCar[`total`] = this.msToString(sumTimeMs);
        }
        updatedCars.push(updatedCar);
      }
      this.cars = updatedCars;
      console.log(this.cars);
    },

    async simulateLap(carId) {
      const now = Date.now();
      await this.$apolloClient.mutate({
        mutation: gql`
          mutation AddLineDetection($detection: LineDetection) {
            addLineDetection(detection: $detection)
          }
        `,
        variables: {
          detection: {
            detectionTimestamp: {
              timestamp: Math.floor(now / 1000),
              ms: now % 1000,
            },
            carId: carId,
          },
        },
      });
      console.log(`lap simulated`);
    },
  },
};
</script>
