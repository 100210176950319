import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import { ApolloClient, InMemoryCache, HttpLink } from "@apollo/client/core";
import { setContext } from '@apollo/client/link/context'
import * as Keycloak from "keycloak-js";
import getEnv from "./config/env.js"


Vue.config.productionTip = false;

let initOptions = {
  url: getEnv("VUE_APP_KEYCLOAK_URL"),
  realm: getEnv("VUE_APP_KEYCLOAK_REALM"),
  clientId: getEnv("VUE_APP_KEYCLOAK_CLIENT_ID"),
  onLoad: "login-required",
};

Vue.prototype.$keycloak = Keycloak(initOptions);

function createApolloclient() {
  const defaultOptions = {
    watchQuery: {
      fetchPolicy: "no-cache",
      errorPolicy: "ignore",
    },
    query: {
      fetchPolicy: "no-cache",
      errorPolicy: "all",
    },
  };

  const headerLink = setContext(async (_, {headers, ...context}) => {
    return {
        headers: {
            ...headers,
            authorization: `Bearer ${Vue.prototype.$keycloak.token}`
        },
        ...context,
      };
  });
  const httpLink = new HttpLink({
      uri: getEnv("VUE_APP_RACE_BACKEND_URI"),
  });
  Vue.prototype.$apolloClient = new ApolloClient({
      cache: new InMemoryCache(),
      link: headerLink.concat(httpLink),
      defaultOptions: defaultOptions,
  });

  const httpLinkUser = new HttpLink({
    uri: getEnv("VUE_APP_USER_BACKEND_URI"),
  });
  Vue.prototype.$apolloClientUser = new ApolloClient({
      cache: new InMemoryCache(),
      link: headerLink.concat(httpLinkUser),
      defaultOptions: defaultOptions,
  });
    console.log(process.env.VUE_APP_RACE_BACKEND_URI, headerLink.concat(httpLink))
    console.log(process.env.VUE_APP_USER_BACKEND_URI, headerLink.concat(httpLinkUser))
  }
Vue.prototype.$keycloak
  .init({ onLoad: initOptions.onLoad })
  .then((auth) => {
    console.log(`keycloak auth: ${auth}`);
    if (!auth) {
      window.location.reload();
    } else {
      console.log(`authenticated`);

      createApolloclient();

      new Vue({
        vuetify,
        render: (h) => h(App),
      }).$mount("#app");
    }

    //Token Refresh
    setInterval(() => {
      Vue.prototype.$keycloak
        .updateToken(70)
        .then((refreshed) => {
          if (refreshed) {
            console.info("Token refreshed" + refreshed);
            console.log(process.env.VUE_APP_RACE_BACKEND_URI)
            createApolloclient();
          } else {
            console.warn(
              "Token not refreshed, valid for " +
                Math.round(
                  Vue.prototype.$keycloak.tokenParsed.exp +
                    Vue.prototype.$keycloak.timeSkew -
                    new Date().getTime() / 1000
                ) +
                " seconds"
            );
          }
        })
        .catch(() => {
          console.error("Failed to refresh token");
        });
    }, 30 * 1000);
  })
  .catch((e) => {
    console.error(`Authentication Failed ${JSON.stringify(e)}`);
  });
